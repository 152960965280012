var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('BaseList',{attrs:{"flat":"","color":"primary","title":_vm.$t('transfer_request_history_operations_deposit'),"section":_vm.$route.meta.section,"headers":_vm.headers,"items":_vm.historics,"loading":_vm.loading,"totalRows":_vm.totalRows,"breadcrumbs":false,"elevation":0,"height":'100%',"buttons":{
      edit: false,
      search: true,
      refresh: true,
      delete: false,
      add: false
    },"search":_vm.search},on:{"refresh":_vm.getData,"delete":_vm.handleDelete,"options":_vm.handleOptions,"search":_vm.handleSearch},scopedSlots:_vm._u([{key:"header.fundType",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_instrument_type'))+" ")])]},proxy:true},{key:"header.operationType",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_operation_type'))+" ")])]},proxy:true},{key:"header.alias",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_index_alias'))+" ")])]},proxy:true},{key:"header.currency",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_currency'))+" ")])]},proxy:true},{key:"header.amount",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_amount'))+" ")])]},proxy:true},{key:"header.schedule.start",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_start_date'))+" ")])]},proxy:true},{key:"header.bnfReference",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_observations'))+" ")])]},proxy:true},{key:"header.status",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('transfer_request_status'))+" ")])]},proxy:true},{key:"item.operationType",fn:function(ref){
    var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value == 'W' ? _vm.$t('transfer_request_type_withdrawal') : _vm.$t('transfer_request_type_deposit')))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }